import React, { useState } from "react";
import { Container, Form, Button, Row, Col, Alert } from "react-bootstrap";
import Layout from "../components/Layout";
import { Link, navigate } from "gatsby";
import { handleSignup } from "../services/auth";

const SignupPage = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSignup(name, companyName, email, password, ({ errors }) => {
      if (errors === null) {
        navigate("/login", { state: { message: "Account created successfully. Log in to begin." } });
      } else {
        setErrorMessage(JSON.stringify(errors));
      }
    });
  };
  return (
    <Layout>
      <Container
        fluid="md"
        className="d-flex flex-row justify-content-center align-items-center"
        style={{ height: "90vh" }}
      >
        <Row className="flex-column align-items-center w-100">
          <Col lg="4">
            <h1 className="text-center">Sign Up</h1>
            <div style={{ borderBottom: "solid white 2px" }} className="pt-3"></div>
            {errorMessage ? <Alert variant="warning">{errorMessage}</Alert> : ""}
          </Col>
          <Col lg="4" className="mt-3">
            <Form onSubmit={(e) => handleSubmit(e)}>
              <Form.Group controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="formBasicCompanyName">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter company name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default SignupPage;
